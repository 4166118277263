import { getApiUrl } from 'data-layer/resolvers';

export const downloadFileByBlob = (response: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(response);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getClientDocumentByTokenAndUserId = (
  token: string,
  userId: string,
  documentId: string,
  fileName?: string
): Promise<void> => {
  return fetch(
    `${getApiUrl().substring(
      0,
      getApiUrl().length - 3
    )}get_client_document?document_id=${documentId}`,
    {
      method: 'GET',
      headers: {
        'x-gbooking-token': token,
        'x-gbooking-userid': userId,
      },
    }
  )
    .then((response) => {
      const fileNameForDownload = fileName ?? response.headers.get('content-disposition')?.split('filename=')[1] ?? 'file';
      return response.blob().then((blob) => ({ blob, fileNameForDownload }))
    })
    .then(({ blob, fileNameForDownload }) => {
      downloadFileByBlob(blob, fileNameForDownload);
    })
    .catch((error) => console.error(error));
};
