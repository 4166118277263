import axios from 'axios';
import { MedMeAPI } from '@gbooking/ts-sdk';
import { IPhoneData, IWebLoginResponse, IRequestCodeResponce, IChangePass } from '../types';
import { getApiUrl } from 'data-layer/resolvers';
import { createRequest } from './createRequest';
import { config, initMedMeSDK } from 'utils';

initMedMeSDK();

export async function loginUser(phone: string, password: string): Promise<IWebLoginResponse> {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions

  return MedMeAPI.otpAuthorize
    .webLogin(phone, password)
    .then((response) => {
      return response as IWebLoginResponse;
    })
    .catch((err) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      Promise.reject(new Error(err.message)),
    );
}

export async function confirmSmsCode(data: {
  code: string;
  token: string;
  businessID: string;
}): Promise<IWebLoginResponse> {
  const client = config.REACT_APP_AUTH_TOKEN || '';

  return MedMeAPI.otpAuthorize
    .verify(client, data.token, data.code, true)
    .then((response) => {
      return response as IWebLoginResponse;
    })
    .catch((err) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return Promise.reject(new Error(err.message));
    });
}

export async function requestSmsCode(
  phone: IPhoneData,
  businessID: string,
  passportId: string,
  lang: string,
): Promise<IRequestCodeResponce> {
  const { country_code, area_code, number } = phone;

  return MedMeAPI.otpAuthorize
    .send(businessID, country_code, area_code, number, passportId, lang)
    .then((response) => {
      return response as IRequestCodeResponce;
    })
    .catch((err) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      Promise.reject(new Error(err.message)),
    );
}

export async function changePass(data: IChangePass): Promise<boolean> {
  const apiUrl = getApiUrl();
  return axios
    .post(apiUrl, createRequest('profile.change_password', data))
    .then((response) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (response.data && response.data.result) {
        return true;
      }

      return false;
    })
    .catch((err) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      Promise.reject(new Error(err.message)),
    );
}
