import styled from '@emotion/styled';
import { unit } from 'styles';

type StackProps = {
    spacing?: number,
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse',
    sx?: Record<string, unknown>,
}

export const Stack = styled('div')(({spacing = 0, direction = 'column', sx}: StackProps) => {
  return {
    display: 'flex',
    gap: unit * spacing,
    flexDirection: direction,
    ...sx,
  };
});
