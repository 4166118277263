import styled from '@emotion/styled';
import { DownloadSpinner } from 'components/DownloadSpinner';
import { ITheme } from 'data-layer/types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientContext, IClientContext } from 'utils';
import { getClientDocumentByTokenAndUserId } from 'utils/file';
import { AvailableExtraField } from './types';

type DownloadAndViewFileButtonProps = {
  availableExtraField: AvailableExtraField;
};

export const DownloadAndViewFileButton: React.FC<DownloadAndViewFileButtonProps> = ({
  availableExtraField,
}: DownloadAndViewFileButtonProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { theme, token, user } = useContext<IClientContext>(ClientContext);
  const { t } = useTranslation();

  const handleDownload = () => {
    setIsDownloading(true);
    void getClientDocumentByTokenAndUserId(
      token,
      user,
      availableExtraField.value ?? '',
      availableExtraField.fileName
        ? availableExtraField.fileName
        : availableExtraField.name
    ).finally(() => setIsDownloading(false));
  };

  return (
    <FileButtonWithLinkStyles
      theme={theme}
      disabled={isDownloading}
      onClick={handleDownload}
    >
      {isDownloading && <DownloadSpinner theme={theme} />}
      {`${
        availableExtraField.fileName
          ? availableExtraField.fileName
          : availableExtraField.name
      } (${t('screens.appointments.download')})`}
    </FileButtonWithLinkStyles>
  );
};

const FileButtonWithLinkStyles = styled('button')(
  (props: { theme: ITheme }) => ({
    display: 'inline-flex',
    gap: 8,
    alignItems: 'end',
    outline: 'none',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    color: props.theme.mainColor,
    textDecoration: 'underline',
    padding: 0,
    fontWeight: 500,
    fontSize: 16,
  })
);
