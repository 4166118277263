import styled from '@emotion/styled';
import { flexCenter, unit } from 'styles';

type IconButtonProps = {
  iconSize: number,
}

export const IconButton = styled('button')(flexCenter, ({iconSize}: IconButtonProps) => {
  return {
    width: unit * iconSize,
    height: unit * iconSize,
    padding: 0,
    border: 'none',
    background: 'none',
    outline: 'none',
    cursor: 'pointer',
  }
});
