import { BusinessBackofficeConfigurationObject } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { getBusiness_getBusinessByID_info } from 'data-layer/queries/__graphql__/getBusiness';
import { GetBusinessInfo_getBusinessInfo } from 'data-layer/queries/__graphql__/GetBusinessInfo';
import { getNetworkData_getNetwork } from 'data-layer/queries/__graphql__/getNetworkData';

export const getDisablePasswordLogin = (
  businessInfo?: GetBusinessInfo_getBusinessInfo | getBusiness_getBusinessByID_info,
  network?: getNetworkData_getNetwork,
): boolean => {
  return network?.businessConfiguration.disablePasswordLoginInClientCabinet ?? businessInfo?.backoffice_configuration.disablePasswordLoginInClientCabinet ?? false
};

export const getDisablePasswordLoginByBackofficeConfiguration = (
  backofficeConfiguration?: BusinessBackofficeConfigurationObject,
  network?: getNetworkData_getNetwork,
): boolean => {
  return network?.businessConfiguration.disablePasswordLoginInClientCabinet ?? backofficeConfiguration?.disablePasswordLoginInClientCabinet ?? false
};
