import React, { useContext } from 'react';
import PinField, { PinFieldProps } from 'react-pin-field';
import styled from '@emotion/styled';

import { breakpointSmall, hideForMobile, unit } from '../styles';
import { ReactComponent as IconError } from '../assets/icon-error.svg';
import { ClientContext } from '../utils';
import { ITheme } from '../data-layer/types';

type CodeInputProps = {
  icon?: 'error' | false;
  getRef?: (refObj: React.MutableRefObject<HTMLInputElement[] | null>) => void;
};

const CodeInput = (props: CodeInputProps & PinFieldProps): JSX.Element => {
  const { icon, getRef } = props;
  const { theme } = useContext(ClientContext);
  const ref = React.useRef<HTMLInputElement[] | null>(null);
  if (getRef) {
    getRef(ref || null);
  }
  return (
    <StyledCodeInput theme={theme}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PinField inputMode="numeric" ref={ref} {...props} />
      {icon ? <IconStyled /> : ''}
    </StyledCodeInput>
  );
};

const StyledCodeInput = styled('div')((props: { theme: ITheme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '& > *': {
    width: unit * 5.6,
    height: unit * 5.6,
    maxWidth: unit * 29.2,
    padding: unit * 1.3,
    margin: `0 ${unit}px`,
    border: `1px solid transparent`,
    borderRadius: unit * 10,
    [`@media screen and (max-width: ${breakpointSmall}px)`]: {
      marginLeft: unit * 0.5,
      marginRight: unit * 0.5,
    },
  },
  '& > input': {
    color: props.theme.textColor,
    fontSize: 26,
    lineHeight: 1.153,
    textAlign: 'center',
    backgroundColor: props.theme.passiveColor,
    transition: 'all 0.3s ease-in-out',
    '&:focus': {
      borderColor: props.theme.mainColor,
    },
  },
  direction: 'ltr',
}));

const IconStyled = styled(IconError)(hideForMobile);

export default CodeInput;
