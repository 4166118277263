// import metadata from 'libphonenumber-js/metadata.min.json';
/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Input, { iconType } from './Input';
import 'react-phone-input-2/lib/style.css';
import styled from '@emotion/styled';
import { unit } from 'styles';
import { checkPassportId } from 'utils/passportIdUtils';

type PassportIdInputProps = {
  passportId: string;
  onPassportIdChange: (passportId: string, passportIdValid: boolean) => void;
  icon: iconType;
  children?: React.ReactNode;
};

export const PassportIdInput: React.FC<PassportIdInputProps> = ({
  passportId,
  onPassportIdChange,
  icon,
  children,
}: PassportIdInputProps) => {
  const { t } = useTranslation();
  const placeHolder = t('components.passportIdInput.placeholder');

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value || '';
    const valid = checkPassportId(value);
    return onPassportIdChange(value, valid);
  };

  return (
    <StyledDiv>
      <Input
        required
        type="text"
        name="passportId"
        value={passportId}
        placeholder={placeHolder}
        data-testid="passport-id-input"
        autoComplete="email"
        onChange={onChangeInput}
        inputMode="numeric"
        icon={icon}
      >
        {children}
      </Input>
    </StyledDiv>
  );
};

const StyledDiv = styled('div')({
  position: 'relative',
  marginBottom: 3 * unit,
});