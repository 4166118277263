export interface IConfig {
  REACT_APP_GBOOKING_COREV2_RPC_URL: string;
  REACT_APP_GBOOKING_OAUTH_URL: string;
  PORT: string;
  REACT_APP_CUSTOMER_PORTAL: string;
  REACT_APP_WIDGET_URL: string;
  REACT_APP_CDN_WORKER_IMG: string;
  REACT_APP_CDN_USER_IMG: string;
  REACT_APP_AUTH_TOKEN: string;
  REACT_APP_EHR_URL: string;
  EHR_REQUEST_APPOINTMENTS_LIMIT: number;
  MOCK_EHR: boolean;
}

export const constants = {
  devConfig: process.env.REACT_APP_HOST_TYPE === 'dev' || false,
  localConfig: process.env.REACT_APP_HOST_TYPE === 'development' || false,
  defaultResourceImage: 'https://cdn.gbooking.ru/widget/doctor_img.png',
  DATE_FORMAT: 'DD.MM.YYYY',
  LANGUAGES: [
    {
      title: 'Русский',
      tag: 'ru',
      code: 'ru-ru',
      countries: ['ru'],
      dateFormat: 'D MMMM YYYY, HH:mm',
    },
    {
      title: 'עברית',
      tag: 'il',
      code: 'he-il',
      countries: ['il'],
      dateFormat: 'D MMMM YYYY, HH:mm',
    },
    {
      title: 'English',
      tag: 'en',
      code: 'en-us',
      countries: ['uk', 'us'],
      dateFormat: 'MMMM D, YYYY, hh:mm A',
    },
  ],
  MOCK_PATIENT_ID: '10041760',
};

const devConfig: IConfig = {
  REACT_APP_GBOOKING_COREV2_RPC_URL: 'https://apiv2.dev.gbooking.ru/rpc',
  REACT_APP_GBOOKING_OAUTH_URL: 'https://oauth.dev.gbooking.ru',
  REACT_APP_CUSTOMER_PORTAL: 'https://my.dev.gbooking.ru',
  REACT_APP_WIDGET_URL: process.env.REACT_APP_WIDGET_URL || 'https://widget.dev.gbooking.ru/',
  REACT_APP_CDN_WORKER_IMG: '//cdn.gbooking.ru/images/workers/',
  REACT_APP_CDN_USER_IMG: '//cdn.gbooking.ru/images/client_avatar/',
  PORT: '4008',
  REACT_APP_AUTH_TOKEN: '55251467c4698837838b4567',
  REACT_APP_EHR_URL: 'https://ehr-app.dev.gbooking.ru/1.7.1-alpha/',
  EHR_REQUEST_APPOINTMENTS_LIMIT: 20,
  MOCK_EHR: false,
};

const localConfig: IConfig = {
  REACT_APP_GBOOKING_COREV2_RPC_URL: 'http://apiv2.gbooking.local:9000/rpc',
  REACT_APP_GBOOKING_OAUTH_URL: 'http://oauth.gbooking.local:9000',
  REACT_APP_CUSTOMER_PORTAL: 'http://my.gbooking.local:9000',
  REACT_APP_WIDGET_URL: process.env.REACT_APP_WIDGET_URL || 'http://widget.gbooking.local:9000/',
  REACT_APP_CDN_WORKER_IMG: '//cdn.gbooking.ru/images/workers/',
  REACT_APP_CDN_USER_IMG: '//cdn.gbooking.ru/images/client_avatar/',
  PORT: '4008',
  REACT_APP_AUTH_TOKEN: '55251467c4698837838b4567',
  REACT_APP_EHR_URL: 'http://ehr-app.dev.gbooking.ru/1.7.1-alpha/',
  EHR_REQUEST_APPOINTMENTS_LIMIT: 20,
  MOCK_EHR: false,
};

const prodConfig: IConfig = {
  REACT_APP_GBOOKING_COREV2_RPC_URL: 'https://apiv2.gbooking.ru/rpc',
  REACT_APP_GBOOKING_OAUTH_URL: 'https://oauthv2.gbooking.ru',
  REACT_APP_CUSTOMER_PORTAL: 'https://my.gbooking.ru',
  REACT_APP_WIDGET_URL: process.env.REACT_APP_WIDGET_URL || 'https://widget.med.me/',
  REACT_APP_CDN_WORKER_IMG: '//cdn.gbooking.ru/images/workers/',
  REACT_APP_CDN_USER_IMG: '//cdn.gbooking.ru/images/client_avatar/',
  PORT: '4008',
  REACT_APP_AUTH_TOKEN: '55251467c4698837838b4567',
  REACT_APP_EHR_URL: 'http://ehr-app.dev.gbooking.ru/1.7.1-alpha/',
  EHR_REQUEST_APPOINTMENTS_LIMIT: 20,
  MOCK_EHR: false,
};

let appConfig = prodConfig;
if (constants.localConfig) {
  appConfig = localConfig;
} else if (constants.devConfig) {
  appConfig = devConfig;
}

export const config: IConfig = appConfig;
