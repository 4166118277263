import React, { useState } from 'react';
import styled from '@emotion/styled';

import Heading from './Heading';
import AuthForm from './AuthForm';
import Button from './button';
import Input, { iconType } from './Input';
import InputAlert from './InputAlert';
import { breakpointLarge, mediumText, unit, contentCSS } from '../styles';
import * as LoginTypes from '../pages/__graphql__/Login';
import { withTranslation, WithTranslation } from 'react-i18next';
import PageContainer from './pageContainer';
import { PhoneInputComponent as PhoneInput } from './PhoneInput';
import { GetBusinessInfo_getBusinessInfo } from 'data-layer/queries/__graphql__/GetBusinessInfo';
import { ClientContext } from '../utils/ClientContext';
import Logo from './Logo';
import { urlManager } from 'utils';
import { useQuery } from '@apollo/react-hooks';
import { getNetworkBranchList, getNetworkBranchListVariables } from 'data-layer/queries/__graphql__/getNetworkBranchList';
import { GET_BUSINESS, GET_NETWORK_BRANCH_LIST } from 'data-layer/queries';
import { getBusiness, getBusinessVariables } from 'data-layer/queries/__graphql__/getBusiness';

interface LoginPasswordFormProps {
  login: (a: { variables: LoginTypes.LoginVariables }) => void;
  phone: string;
  businessInfo: GetBusinessInfo_getBusinessInfo;
  onPhoneChange: (phone: string, country: string) => void;
  switchAuth: () => void;
  error?: boolean;
  country?: string;
}

const LoginPasswordForm: React.FC<LoginPasswordFormProps & WithTranslation> = ({
  login,
  phone,
  businessInfo,
  onPhoneChange,
  switchAuth,
  error,
  country,
  t,
}) => {
  const [phoneState, setPhoneState] = useState({
    phone,
    country: country || '',
    phoneValid: true,
    password: '',
    passwordVisible: false,
    error: !!error,
  });
  const { theme } = React.useContext(ClientContext);

  const onChangePhone = (phone: string, phoneValid: boolean, countryCode?: string) => {
    const { country: countryState } = phoneState;
    if (countryCode !== countryState) {
      onPhoneChange(phone, countryCode || '');
    }
    setPhoneState({
      ...phoneState,
      phone,
      country: countryCode || '',
      phoneValid,
      error: false,
    });
  };

  const onChangePass = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = (event.target as HTMLInputElement).value;
    event.target.scrollIntoView();
    setPhoneState({ ...phoneState, password, error: false });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { phone, password, country } = phoneState;
    event.preventDefault();
    login({
      variables: {
        phone,
        password,
        country,
      },
    });
  };

  const onTogglePasswordVisibility = () => {
    const { passwordVisible } = phoneState;
    setPhoneState({ ...phoneState, passwordVisible: !passwordVisible });
  };

  const renderPasswordRow = () => {
    const { passwordVisible, password, error, phoneValid } = phoneState;
    const isPassInvalid = !password.length;
    const icon: iconType = passwordVisible ? 'visible' : 'hidden';

    return (
      <PasswordRow>
        <Input
          required
          type={passwordVisible ? 'text' : 'password'}
          name="password"
          placeholder=""
          value={password}
          icon={error ? 'error' : icon}
          autoComplete="current-password"
          data-testid="password-input"
          onChange={onChangePass}
          onIconClick={onTogglePasswordVisibility}
        >
          {error && <InputAlert theme={theme}>{t('components.auth.error')}</InputAlert>}
        </Input>
        <SubmitButton theme={theme} type="submit" disabled={isPassInvalid || !phoneValid}>
          {t('components.auth.submitButtonLabel')}
        </SubmitButton>
      </PasswordRow>
    );
  };

  const networkID = urlManager.getNetworkId();
  const businessID = urlManager.getBusinessId();
  const { data: networkData} = useQuery<getNetworkBranchList, getNetworkBranchListVariables>(
    GET_NETWORK_BRANCH_LIST,
    {
      variables: { networkID, contractExtraId: urlManager.getContractExtraId() },
      skip: !networkID,
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: businessData} = useQuery<getBusiness, getBusinessVariables>(GET_BUSINESS, {
    variables: {
      id: businessID,
      contractID: urlManager.getContractId(),
      contractExtraId: urlManager.getContractExtraId(),
    },
    skip: !businessID,
  });

  return (
    <PageContainerStyled title={t('title.authTitle')} layoutClassName="login" contentCSS={contentCSS}>
      <LogoWithMargin url={urlManager.getLogoUrl({ networkID, businessID, networkData, businessData })}/>
      <StyledHeading>{t('components.auth.phoneNumber')}</StyledHeading>
      <AuthForm onSubmit={onSubmit}>
        <PhoneInput
          phone={phoneState.phone}
          country={phoneState.country}
          icon={phoneState.phone.length ? (error || !phoneState.phoneValid ? 'error' : 'success') as iconType : ''}
          onPhoneChange={onChangePhone}
          businessInfo={businessInfo}
        >
          {!phoneState.phoneValid && (
            <InputAlert theme={theme}>{t('components.auth.errorPhone')}</InputAlert>
          )}
        </PhoneInput>
        <Label>{t('components.auth.password')}</Label>
        {renderPasswordRow()}
        <Button theme={theme} variant="link" onClick={switchAuth}>
          {t('components.auth.forgetPasswordButtonLabel')}
        </Button>
      </AuthForm>
    </PageContainerStyled>
  );
};

export default withTranslation()(LoginPasswordForm);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */
 
const PageContainerStyled = styled(PageContainer)({
  overflow: 'auto'
});

const StyledHeading = styled(Heading)({
  marginBottom: unit * 3,
});

const PasswordRow = styled('div')({
  position: 'relative',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    padding: `0 ${unit * 9}px`,
  },
});

const Label = styled('div')(mediumText, {
  marginTop: unit * 3,
});

const SubmitButton = styled(Button)({
  minWidth: 'auto',
  [`@media screen and (min-width: ${breakpointLarge + 1}px)`]: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    [`[dir="ltr"] &`]: {
      right: 0,
    },
    [`[dir="rtl"] &`]: {
      left: 0,
    },
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    margin: `${unit * 2.5}px auto 0`,
  },
});

const LogoWithMargin = styled(Logo)({
  marginBottom: unit * 8,
});
