import React from 'react';
import ReactDOM from 'react-dom';
import { createApolloClient } from './data-layer';
import { ApolloProvider } from '@apollo/react-hooks';
import i18n from './utils/i18n';

import { urlManager } from 'utils/urlManager';
import { constants } from 'utils';
import { MODAL_ELEMENT_ID } from 'components';
import App from 'App';

interface IgbookingWidgetSetup {
  preload: boolean;
  url?: string;
  css?: string;
  networkId?: string;
  businessId?: string;
  widgetWrapperId?: string;
  contractExtraId?: string;
  contractID?: string;
  clearClientData?: boolean;
}

declare global {
  interface Window {
    gbookingWidgetSetup: IgbookingWidgetSetup;
    GBooking: {
      openWidget (params: unknown): void;
      closeWidget (): void;
    };
  }
}
const gbookingWidgetSetup: IgbookingWidgetSetup = {
  preload: false,
  css: 'iframe',
  widgetWrapperId: MODAL_ELEMENT_ID,
  url: constants.devConfig ? 'https://widget.dev.gbooking.ru/' : undefined,
  clearClientData: false
};
if (constants.localConfig) {
  gbookingWidgetSetup.url = 'http://widget.gbooking.local:9000/';
}
const businessID = urlManager.getBusinessId();
const networkID = urlManager.getNetworkId();
const contractExtraId = urlManager.getContractExtraId();
const contractID = urlManager.getContractId();

if (networkID.length) {
  gbookingWidgetSetup.networkId = networkID;
} else if (businessID.length) {
  gbookingWidgetSetup.businessId = businessID;
}
if (contractExtraId) {
  gbookingWidgetSetup.contractExtraId = contractExtraId;
} else if (contractID) {
  gbookingWidgetSetup.contractID = contractID;
}

window.gbookingWidgetSetup = gbookingWidgetSetup;
async function main() {
  const pageTitle = i18n.t('pageTitle')
  if (pageTitle)
    document.title = pageTitle

  const apolloClient = await createApolloClient();

  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>,
    document.getElementById('root'),
  );
}

// eslint-disable-next-line @typescript-eslint/no-floating-promises
main();
