/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
import React from 'react';
import { IEHRCounter, ITheme, Lang } from 'data-layer/types';
import { PatientProperties } from 'data-layer/helpers';
import moment from 'moment';
import { getBusiness_getBusinessByID_info } from 'data-layer/queries/__graphql__/getBusiness';
import { constants } from './config';
import { getNetworkData_getNetwork } from 'data-layer/queries/__graphql__/getNetworkData';
import { GetBusinessInfo_getBusinessInfo } from 'data-layer/queries/__graphql__/GetBusinessInfo';
import { BusinessBackofficeConfigurationObject } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

export interface IgetResourceNameParam {
  name: string;
  surname: string;
  middleName?: string | null;
}

export interface IClientContext {
  clientId: string;
  clientInfo: PatientProperties;
  clientInfoPending: boolean;
  patientId: string;
  user: string;
  token: string;
  expires: string;
  businessId: string;
  networkId: string;
  ehrCounter: IEHRCounter;
  ehrCounterSupport: boolean;
  ehrEnabled: boolean;
  ehrEndPoint: string;
  ehrEntities: string[]
  timezone: string;
  showExtraFieldsInClientCabinet: boolean;
  nationalMode: boolean;
  country: string;
  businessInfo?: getBusiness_getBusinessByID_info;
  backofficeConfiguration?: BusinessBackofficeConfigurationObject;
  network?: getNetworkData_getNetwork;
  refetchClient: () => void;
  lang: Lang;
  theme: ITheme;
  getResourceName: (param: IgetResourceNameParam) => string;
}
const date = moment.utc().toDate();

export const defaultTheme: ITheme = {
  backdropColor: '#f1f1f2',
  backgroundColor: '#fcfcfc',
  chevronColor: '#a4a4a5',
  itemBackgroundColor: '#f0eef1',
  pastItemBackgroundColor: '#f0eef1',
  telemedItemBackgroundColor: '#c7ecf8',
  startRecordBackgroundColor: 'rgba(255, 255, 255, 0)',
  mainColor: '#47C4E4',
  passiveColor: '#f0eef1',
  textColor: '#050607',
  clientCabinetTranslates: '{}',
  font: {
    name: 'Roboto',
    regular: 'RobotoRegular',
    medium: 'RobotoMedium',
    bold: 'RobotoBold',
    light: 'RobotoLight',
  },
  patientNoAppearColor: '#5a5a5a',
};

export const defaultClientInfo: PatientProperties = {
  id: '',
  name: '',
  date,
  middleName: '',
  surname: '',
  gender: 0,
  email: '',
  phone: '',
  phoneString: '',
  passportId: '',
  iconURL: '',
};

export const defaultContext: IClientContext = {
  clientId: '',
  patientId: '',
  clientInfo: defaultClientInfo,
  clientInfoPending: false,
  user: '',
  token: '',
  expires: '',
  businessId: '',
  ehrCounter: {
    appointment: 0,
    appointmentResult: 0,
    prescription: 0,
    diagnosticReport: 0,
  },
  networkId: '',
  ehrCounterSupport: false,
  ehrEnabled: false,
  ehrEndPoint: '',
  ehrEntities: [],
  timezone: 'Europe/Moscow',
  showExtraFieldsInClientCabinet: false,
  nationalMode: false,
  country: 'RU',
  businessInfo: undefined,
  network: undefined,
  refetchClient: () => {},
  lang: constants.LANGUAGES[0],
  theme: defaultTheme,
  getResourceName: ({ name, surname }) => `${name} ${surname}`,
};

export const ClientContext = React.createContext<IClientContext>(defaultContext);
