import { gql } from 'apollo-boost';

export const GET_NETWORK_DATA = gql`
  query getNetworkData($networkID: ID!, $contractID: String, $contractExtraId: String) {
    getNetwork(id: $networkID, contractID: $contractID, contractExtraId: $contractExtraId) @client {
      networkName
      integrationData {
        ehr {
          active
          protocol
          host
          port
          path
          availableEntities
        }
      }
      businessConfiguration {
        newWidgetTheme {
          mainColor
          backgroundColor
          itemBackgroundColor
          pastItemBackgroundColor
          telemedItemBackgroundColor
          startRecordBackgroundColor
          passiveColor
          textColor
          backdropColor
          chevronColor
          clientCabinetTranslates
          patientNoAppearColor
        }
        disablePasswordLoginInClientCabinet
      }
    }
  }
`;
