import styled from '@emotion/styled';
import { ITheme } from 'data-layer/types';
import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { ClientContext, IClientContext } from 'utils';
import 'react-toastify/dist/ReactToastify.css';

export const ToastContainerWithTheme: React.FC = () => {
  const { theme } = useContext<IClientContext>(ClientContext);

  return <ToastContainerStyled myTheme={theme}/>
}

const ToastContainerStyled = styled(ToastContainer)`
    --toastify-icon-color-success: ${({myTheme}: {myTheme: ITheme}) => myTheme.mainColor};
    --toastify-text-color-light: ${({myTheme}: {myTheme: ITheme}) => myTheme.textColor};
    --toastify-color-progress-success: ${({myTheme}: {myTheme: ITheme}) => myTheme.mainColor};
`;