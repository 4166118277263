/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from 'axios';

import {
  IContractInfo,
  IGetUserInfoRequest,
  IUpdateUserInfo,
  IUpdateUserInfoRequest,
  IUserInfoResponce,
} from '../types';
import { createRequest } from './createRequest';
import { MedMeAPI } from '@gbooking/ts-sdk';
import { urlManager } from 'utils/urlManager';
import { initMedMeSDK } from 'utils';
import { ClientFindOfCreateClientResponseResult, ClientFindOrCreateClientRequestParams, Cred } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

initMedMeSDK();

export async function findOrCreateClient(
  cred: Cred,
  businessID: string,
): Promise<ClientFindOfCreateClientResponseResult> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (MedMeAPI.setCredentials) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    MedMeAPI.setCredentials(cred);
  }
  const contractExtraId = urlManager.getContractExtraId();

  const networkID = urlManager.getNetworkId();
  const params: ClientFindOrCreateClientRequestParams = {
    business: {
      id: businessID,
    },
  };

  if (networkID) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    params.network = {
      id: networkID,
    };
  }
  if (contractExtraId) {
    params.contract = {
      contractExtraId,
    };
  }
  const clientData: ClientFindOfCreateClientResponseResult = await MedMeAPI.client.findOrCreateClient(
    params,
  );
  if (MedMeAPI.clearCredentials) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    MedMeAPI.clearCredentials();
  }
  return clientData;
}

export async function getUserInfo(
  cred: Cred,
  GBOOKING_COREV2_RPC_URL: string,
): Promise<IUserInfoResponce> {
  const params = {} as IGetUserInfoRequest;
  const contractID = urlManager.getContractId();
  const contractExtraId = urlManager.getContractExtraId();
  if (contractID || contractExtraId) {
    params.contract = {} as IContractInfo;
    if (contractID) {
      params.contract.id = contractID;
    }
    if (contractExtraId) {
      params.contract.extraId = contractExtraId;
    }
  }
  return axios
    .post(GBOOKING_COREV2_RPC_URL, createRequest('profile.get_profile_info', params, cred))
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        return response?.data?.result;
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<IUserInfoResponce>;
}

export async function updateUserInfo(
  userInfo: IUpdateUserInfo,
  cred: Cred,
  GBOOKING_COREV2_RPC_URL: string,
): Promise<boolean> {
  const params: IUpdateUserInfoRequest = {
    name: userInfo.name,
    middleName: userInfo.middleName,
    surname: userInfo.surname,
    birthday: userInfo.birthday,
    gender: userInfo.gender,
    passportId: userInfo.passportId,
  };
  const businessId = urlManager.getBusinessId();
  const networkId = urlManager.getNetworkId();
  const contractID = urlManager.getContractId();
  const contractExtraId = urlManager.getContractExtraId();
  if (contractID || contractExtraId) {
    params.contract = {} as IContractInfo;
    if (contractID) {
      params.contract.id = contractID;
    }
    if (contractExtraId) {
      params.contract.extraId = contractExtraId;
    }
    if (businessId) {
      params.contract.businessID = businessId;
    }
    if (networkId) {
      params.contract.networkID = networkId;
    }
  }
  return axios
    .post(GBOOKING_COREV2_RPC_URL, createRequest('profile.update_profile_info', params, cred))
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        return response?.data?.result;
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<boolean>;
}

export async function uploadPhotoAPI(
  fileToken: string,
  cred: Cred,
  GBOOKING_COREV2_RPC_URL: string,
): Promise<IUserInfoResponce> {
  return axios
    .post(
      GBOOKING_COREV2_RPC_URL,
      createRequest('profile.update_image', { fileToken: fileToken || '' }, cred),
    )
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        return response?.data?.result;
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<IUserInfoResponce>;
}

export async function removePhotoAPI(
  cred: Cred,
  GBOOKING_COREV2_RPC_URL: string,
): Promise<IUserInfoResponce> {
  return axios
    .post(
      GBOOKING_COREV2_RPC_URL,
      createRequest('profile.remove_image', { remove_image: true }, cred),
    )
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        return response?.data?.result;
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<IUserInfoResponce>;
}
