import {
  Appointment,
  BusinessId,
  Field,
  FieldFull,
} from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { MedMeAPI } from '@gbooking/ts-sdk';
import { GetAppointment_getAppointment_extraFields } from 'data-layer/queries/__graphql__/GetAppointment';
import React, { useEffect, useState } from 'react';
import { urlManager } from 'utils';
import { AppointmentExtraFieldsForm } from './AppointmentExtraFieldsForm';
import { AvailableExtraField } from './types';

type AppointmentExtraFieldsProps = {
  appointmentExtraFields: GetAppointment_getAppointment_extraFields[];
  onSubmitExtraFields: (
    extraFields: GetAppointment_getAppointment_extraFields[]
  ) => Promise<Appointment>;
};

export const AppointmentExtraFields: React.FC<AppointmentExtraFieldsProps> = ({
  appointmentExtraFields,
  onSubmitExtraFields,
}: AppointmentExtraFieldsProps) => {
  const [availableExtraFields, setAvailableExtraFields] = useState<
    AvailableExtraField[]
  >([]);

  useEffect(() => {
    const businessID = urlManager.getBusinessId();
    const params = {
      business: {
        id: businessID,
      },
    } as BusinessId;
    void MedMeAPI.field
      .getExtraFields(params)
      .then((res: Field[]) =>
        setAvailableExtraFields(
          getExtraFieldsAvailableInAppointment(res, appointmentExtraFields)
        )
      );
  }, []);

  const matchAppointmentExtraFieldByExtraFields = (
    extraField: FieldFull,
    appointmentExtraFields: GetAppointment_getAppointment_extraFields[]
  ): GetAppointment_getAppointment_extraFields | undefined => {
    return appointmentExtraFields?.find((field) => {
      return field.fieldID.toString() === extraField.id.toString();
    });
  };

  const getExtraFieldsAvailableInAppointment = (
    extraFields: Field[],
    appointmentExtraFields: GetAppointment_getAppointment_extraFields[]
  ): AvailableExtraField[] => {
    return extraFields
      .filter((field) => field.field.availableInClientCabinet && field.field.active)
      .map((extraField) => {
        const extraFieldField = extraField.field;
        const appointmentExtraField = matchAppointmentExtraFieldByExtraFields(
          extraFieldField,
          appointmentExtraFields
        );
        return {
          id: extraFieldField.id,
          name: extraFieldField.name,
          value:
            appointmentExtraField?.value ||
            undefined,
          fileName: appointmentExtraField?.name || undefined,
          fieldType: extraFieldField.fieldType,
          availableForEditing:
            extraFieldField.availableForEditingInClientCabinet,
        } as AvailableExtraField;
      });
  };

  return availableExtraFields && availableExtraFields.length ? (
    <AppointmentExtraFieldsForm
      availableExtraFields={availableExtraFields}
      onSubmitExtraFields={onSubmitExtraFields}
    />
  ) : null;
};
