/* eslint-disable react/require-default-props */
import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { breakpointMedium, breakpointSmall, unit } from '../styles';
import ProfileForm from 'components/ProfileForm';
import * as IUserInfo from 'data-layer/mutations/__graphql__/UpdateUserInfo';
import { useTranslation } from 'react-i18next';
import { medMeServices, PatientProperties } from 'data-layer/helpers';
import { EHR } from 'medme-ehr-js-sdk';
import { PatientInputProperties } from 'medme-ehr-js-sdk/dist/es5/types';
import { SetPatientIdVariables } from 'data-layer/mutations/__graphql__/SetPatientId';
import { AuthenticateInputResultCallback } from 'medme-ehr-js-sdk/dist/es5/services/AuthService';
import { config, constants, ClientContext } from 'utils';
import { ICred } from 'data-layer/types/ICred';
import { ITheme } from 'data-layer/types';

interface EhrLoginProps {
  setPatientData: (a: { variables: SetPatientIdVariables }) => void;
}

export const EhrLogin: React.FC<EhrLoginProps> = ({ setPatientData }: EhrLoginProps) => {
  const ehrSubmitHandler = useRef<any>();

  const [ehrError, setEhrError] = useState('');
  const [ehrHandlerAvailable, setEhrHandlerAvailable] = useState(false);
  const { t } = useTranslation();
  const clientContext = useContext(ClientContext);
  const { networkId, businessId, user, token, clientInfo, ehrEndPoint } = clientContext;
  const cred: ICred = {
    user,
    token,
  };
  const medmeServices = medMeServices({
    businessId,
    networkId,
    clientId: clientInfo.id,
    user,
    token,
    ehrEndPoint,
  });
  const authPatient = async () => {
    return new Promise<void>((resolve) => {
      EHR.Services.getAuthenticatedPatient(
        medmeServices.patientService,
        medmeServices.authService,
        function getPatientInput (next: AuthenticateInputResultCallback) {
          setEhrHandlerAvailable(true)
          ehrSubmitHandler.current = next;
          resolve();
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        function authenticatedPatient (err, data) {
          // Обрабатываем ошибки авторизации
          const { PatientAuthenticationError } = EHR.Services;
          // Ошибка - сервер ЭМК недоступен.
          // Проверку на ошибку недоступности ЭМК необходимо производить перед
          // проверкой на ошибку соединения сети.
          if (err && PatientAuthenticationError.isEhrServerDisabled(err)) {
            setEhrError(t('screens.ehr.ehrDisabled'));

            return false;
          }

          // Ошибка соединения сети
          if (err && PatientAuthenticationError.isConnectionError(err)) {
            setEhrError(t('screens.ehr.ehrConnectionFailed'));
            return false;
          }

          // Ошибка авторизации
          if (
            err &&
            (PatientAuthenticationError.isAuthorizationError(err) ||
              PatientAuthenticationError.patientAlreadyMatched(err))
          ) {
            // return handleAuthorizationError(err, dispatch);
            setEhrError(t('screens.ehr.ehrAuthorizationError'));
            return false;
          }

          // Обрабатываем ошибку аутентификации
          if (err && PatientAuthenticationError.isAuthenticationError(err)) {
            // throw Error(t('screens.ehr.ehrAuthorizationError'));
            setEhrError(t('screens.ehr.ehrAuthorizationError'));
            return false;
          }

          // Обрабатываем остальные ошибки
          // Показать пользователю, что что-то пошло не так, дать сообщение с просьбой отправить тех. информацию
          if (err || !data || !data.patient) {
            setEhrError(t('screens.ehr.ehrErrorTitle'));
            return false;
          }
          setEhrError('');
          // Сохраняем данные аутентификации в локальный in_memory кеш

          if (data?.patient.id) {
            setPatientData({ variables: { id: data?.patient.id } });
            return true;
          }

          return false;
        },
      );
    });
  };


  const submitProfileHandler = (a: { variables: IUserInfo.UpdateUserInfoVariables }) => {
    if (config.MOCK_EHR) {
      setPatientData({ variables: { id: constants.MOCK_PATIENT_ID } });
      return;
    }
    // eslint-disable-next-line prefer-object-spread
    const patientInfo: PatientProperties = Object.assign({}, clientInfo, a.variables);
    patientInfo.phone = patientInfo.phoneString;
    if (ehrSubmitHandler.current && typeof ehrSubmitHandler.current === 'function') {
      const searchStrategy = patientInfo.medCardId ? 'MEDCARD' : 'PHONE';
      /* eslint-disable
          @typescript-eslint/no-unsafe-return,
          @typescript-eslint/no-unsafe-assignment,
          no-return-await
        */
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      ehrSubmitHandler.current(
        null,
        searchStrategy,
        (patientInfo as unknown) as PatientInputProperties,
        patientInfo.medCardId || '',
      );
      /* eslint-enable
          @typescript-eslint/no-unsafe-return,
          @typescript-eslint/no-unsafe-assignment,
          no-return-await
          */
    } else {
      setEhrError(t('screens.ehr.ehrError'));
    }
  };

  const SkipHandler = () => {
    /* eslint-disable
      no-restricted-globals,
      @typescript-eslint/no-unsafe-call
      */
    history.back();
    /* eslint-enable
      no-restricted-globals,
      @typescript-eslint/no-unsafe-call
      */
  };

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  useEffect(() => { authPatient(); }, [])

  return (
    <>
      {
        ehrHandlerAvailable &&
        <ProfileForm
          cred={cred}
          clientContext={clientContext}
          onSave={submitProfileHandler}
          showSkipBtn
          ehrLogin={true}
          onSkip={SkipHandler}
          confirmButtonTitle={t('components.profileForm.applyButtonFind')}
          customLabel={t('screens.ehr.customProfileEditLabel')}
        />
      }
      {
        !ehrHandlerAvailable &&
        <Title>
          {t('screens.ehr.connecting')}
        </Title>
      }
      <ErrorBlock>{ehrError}</ErrorBlock>
    </>
  );
};

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const ErrorBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
  paddingBottom: unit * 6,
  color: 'red',
});

const Title = styled('h3')(() => ({
  //color: props.theme.mainColor,
  fontWeight: 900,
  fontSize: 20,
  lineHeight: 1.15,
  textAlign: 'center',
  marginBottom: `${unit}px`,
  marginTop: `${unit * 2}px`,
  [`@media screen and (max-width: ${breakpointMedium}px)`]: {
    marginLeft: 0,
    marginRight: 0,
  },
  [`@media screen and (max-width: ${breakpointSmall}px)`]: {
    [`[dir="ltr"] &`]: {
      marginLeft: unit,
    },
    [`[dir="rtl"] &`]: {
      marginRight: unit,
    },
  },
}));