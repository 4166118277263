/* eslint-disable @typescript-eslint/no-unsafe-return */
// eslint-disable-next-line max-classes-per-file
import React, { Component, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import Select, { StylesConfig, ValueType } from 'react-select';
import { Button, Input, Datepicker, Agreement } from 'components';
import { iconType } from './Input';
import { unit, flexCenter, breakpointLarge } from '../styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Gender } from './../__graphql__/globalTypes.d';
import { UpdateUserInfoVariables } from 'data-layer/mutations/__graphql__/UpdateUserInfo';
import { constants, IClientContext } from 'utils';
import { ClientContext } from 'utils/ClientContext';
import { IUserCombinedInfo } from 'data-layer/types/IUserCombinedInfo';
import moment from 'moment';
import { ICred } from 'data-layer/types/ICred';

interface OptionTypeGender {
  value: Gender;
  label: string;
}

interface IProfileFormProps {
  clientContext: IClientContext;
  cred?: ICred;
  onSave: (a: { variables: UpdateUserInfoVariables }) => void;
  showSkipBtn?: boolean;
  onSkip?: () => void;
  cancelButtonTitle?: string;
  confirmButtonTitle?: string;
  customLabel?: string;
  agreementLink?: string;
  ehrLogin?: boolean
}

interface IProfileFormState extends IUserCombinedInfo {
  isFormTouched: boolean;
  isNameValid: boolean;
  isSurnameValid: boolean;
  isMiddleNameValid: boolean;
  isBirthdayValid: boolean;
  isPassportIdValid: boolean;
  medCardId?: string;
  requireAgreement: boolean;
  agreeWithAgreement: boolean;
}
interface OptionType {
  label: string;
  value: Gender;
}

// eslint-disable-next-line react/prefer-stateless-function
class ProfileForm extends Component<IProfileFormProps & WithTranslation, IProfileFormState> {
  // // eslint-disable-next-line react/static-property-placement
  // static contextType = ClientContext;

  constructor(props: IProfileFormProps & WithTranslation, context?: unknown) {
    super(props, context);
    const { clientContext, agreementLink } = props;
    const { clientInfo } = clientContext;
    const { name, surname, middleName, passportId, email } = clientInfo;
    const birthday = moment.utc(clientInfo.date).format(constants.DATE_FORMAT);
    const gender = !clientInfo.gender ? Gender.male : Gender.female;

    this.state = {
      name,
      surname,
      middleName,
      birthday,
      gender,
      passportId,
      isFormTouched: false,
      isNameValid: !!name?.length,
      // eslint-disable-next-line react/no-unused-state
      email: [email],
      // eslint-disable-next-line react/no-unused-state
      isPassportIdValid: !!passportId?.length,
      isSurnameValid: !!surname?.length,
      isMiddleNameValid: !!middleName?.length,
      isBirthdayValid: !moment(birthday, constants.DATE_FORMAT).isValid(),
      requireAgreement: !!agreementLink,
      agreeWithAgreement: !agreementLink,
    };
  }

  componentWillUnmount() {
    this.setState({
      isFormTouched: false,
      isNameValid: true,
      isSurnameValid: true,
      isMiddleNameValid: true,
      isBirthdayValid: true,
    });
  }

  componentDidUpdate(prevProps: IProfileFormProps) {
    const { clientContext: { clientInfo } } = this.props;
  
    if (prevProps.clientContext.clientInfo !== clientInfo) {
      const { name, surname, middleName, passportId, email, date } = clientInfo;
      const birthday = moment.utc(date).format(constants.DATE_FORMAT);
      const gender = !clientInfo.gender ? Gender.male : Gender.female;
  
      this.setState({
        name,
        surname,
        middleName,
        birthday,
        gender,
        passportId,
        email: [email],
        isNameValid: !!name?.length,
        isSurnameValid: !!surname?.length,
        isMiddleNameValid: !!middleName?.length,
        isBirthdayValid: !moment(birthday, constants.DATE_FORMAT).isValid(),
      });
    }
  }

  handleGenderChange = (option?: OptionTypeGender) => {
    if (option?.value) {
      this.setState({
        gender: option?.value,
      });
    }
  };

  onChangeBirthday = (birthday: string) => {
    this.setState({
      birthday,
      isBirthdayValid: !moment(birthday, constants.DATE_FORMAT).isValid(),
    });
  };

  onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    const name = `${event.target.value}` || '';
    this.setState({ name, isNameValid: !!name.length });
  };

  onChangeSurName = (event: ChangeEvent<HTMLInputElement>) => {
    const surname = `${event.target.value}` || '';
    this.setState({ surname, isSurnameValid: !!surname.length });
  };

  onChangeMiddleName = (event: ChangeEvent<HTMLInputElement>) => {
    const middleName = `${event.target.value}` || '';
    this.setState({ middleName, isMiddleNameValid: !!middleName.length });
  };

  onChangeMedCard = (event: ChangeEvent<HTMLInputElement>) => {
    const medCardId = `${event.target.value}` || '';
    this.setState({ medCardId });
  };

  onChangePassportId = (event: ChangeEvent<HTMLInputElement>) => {
    const passportId = `${event.target.value}` || '';
    this.setState({ passportId });
  };

  onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const { onSave, cred } = this.props;
    const { birthday, name, surname, middleName, gender, medCardId, passportId } = this.state;

    return onSave({
      variables: {
        birthday,
        name,
        middleName,
        surname,
        gender,
        medCardId,
        passportId,
        user: cred?.user || '',
        token: cred?.token || '',
      },
    });
  };

  onSubmitClick = (): void => {
    this.setState({ isFormTouched: true });
  };

  render() {
    const { theme } = this.context as React.ContextType<typeof ClientContext>;
    const { t, showSkipBtn, onSkip, customLabel, agreementLink, clientContext, ehrLogin } = this.props;
    const selectStyles: any = {
      container: (provided: any) => ({
        ...provided,
        width: '100%',
        maxWidth: unit * 29.2,
      }),
      control: (provided: any) => ({
        ...provided,
        padding: `${unit * 0.85}px ${unit * 2.4}px`,
        borderRadius: unit * 10,
        backgroundColor: 'transparent',
        '&, &:hover': {
          borderColor: 'transparent',
          boxShadow: 'none',
        },
      }),
      indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none',
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        padding: 0,
        fontSize: 26,
        lineHeight: 1.153,
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        color: theme.mainColor,
      }),
    };
    const {
      isFormTouched,
      isNameValid,
      isSurnameValid,
      isMiddleNameValid,
      isBirthdayValid,
      requireAgreement,
      agreeWithAgreement,
    } = this.state;
    const {
      confirmButtonTitle = t('components.profileForm.applyButton'),
      cancelButtonTitle = t('components.profileForm.cancelButton'),
    } = this.props;
    const { birthday, name, middleName, surname, gender, medCardId, passportId } = this.state;
    const itemMale = t('screens.appointments.form.genderMale');
    const itemFemale = t('screens.appointments.form.genderFemale');
    const labelText = customLabel || t('components.profileForm.label');
    const genderOptions: OptionTypeGender[] = [
      {
        label: itemMale,
        value: Gender.male,
      },
      {
        label: itemFemale,
        value: Gender.female,
      },
    ];
    const selectedGender = genderOptions.find((o) => o.value === gender);
    const getIcon = (isValid: boolean): iconType => {
      if (!isFormTouched) return '';
      return isValid ? 'success' : 'error';
    };

    const country = clientContext.businessInfo?.general_info.address[0].country;

    return (
      <StyledForm onSubmit={this.onSubmit}>
        <StyledLabel>{labelText}</StyledLabel>
        <Input
          required
          icon={getIcon(isNameValid)}
          type="text"
          name="name"
          onChange={this.onChangeName}
          value={name}
          placeholder={t('components.profileForm.name')}
        />
        {country !== 'IL' && (
          <Input
            required
            icon={getIcon(isMiddleNameValid)}
            type="text"
            onChange={this.onChangeMiddleName}
            name="middleName"
            value={middleName}
            placeholder={t('components.profileForm.middleName')}
          />
        )}
        <Input
          required
          icon={getIcon(isSurnameValid)}
          type="text"
          onChange={this.onChangeSurName}
          name="surname"
          value={surname}
          placeholder={t('components.profileForm.surname')}
        />
        <Select
          styles={selectStyles}
          name="gender"
          className="gender-selector"
          placeholder={t('screens.appointments.form.select')}
          onChange={(option?: any) =>
            this.handleGenderChange(option as OptionTypeGender)
          }
          options={genderOptions}
          value={selectedGender}
          isSearchable={false}
        />
        <Datepicker
          selected={birthday}
          onDateChange={this.onChangeBirthday}
          error={isBirthdayValid}
        />
        {country && country === 'IL' ? (
          <Input
            type="text"
            onChange={this.onChangePassportId}
            name="passportId"
            value={passportId}
            placeholder={t('components.profileForm.passportId')}
          />
        ) : (
            <Input
              required={false}
            type="text"
            onChange={this.onChangeMedCard}
            name="medcard"
            value={medCardId}
            placeholder={t('components.profileForm.medCard')}
          />
        )}

        {!agreeWithAgreement && agreementLink && (
          <Agreement
            value={agreeWithAgreement}
            src={agreementLink}
            onChange={(value) => this.setState({ agreeWithAgreement: value })}
          />
        )}
        {(requireAgreement && !agreeWithAgreement) || (
          <Buttons>
            {showSkipBtn && (
              <Button theme={theme} variant="transparent" type="button" onClick={onSkip}>
                {cancelButtonTitle}
              </Button>
            )}
            <Button theme={theme} type="submit" onClick={this.onSubmitClick}>
              {confirmButtonTitle}
            </Button>
          </Buttons>
        )}
      </StyledForm>
    );
  }
}

ProfileForm.contextType = ClientContext;

export default withTranslation()(ProfileForm);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const StyledForm = styled('form')(flexCenter, {
  flexDirection: 'column',
  margin: 'auto',
  paddingBottom: unit * 2,
  width: '100%',
  '& > *:not(.react-datepicker__tab-loop):not(:last-child)': {
    marginBottom: unit * 2,
  },
  [`@media screen and (max-width: ${breakpointLarge}px)`]: {
    paddingBottom: unit * 6,
  },
});

const StyledLabel = styled('div')({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1.21,
  textAlign: 'center',
  marginBottom: unit * 5,
});

const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  maxWidth: unit * 29.2,
  marginTop: unit * 6.8,
  Button: {
    flexGrow: 1,
    width: '100%',
    maxWidth: '50%',
    minWidth: 'auto',
    padding: `${unit * 1.2}px 0`,
  },
});
