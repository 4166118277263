import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IsLoggedIn } from './pages/IsLoggedIn';
import { ClientContextProvider, ContentDirection, WidgetModal } from 'components';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <ClientContextProvider>
        <ContentDirection />
        <IsLoggedIn />
        <WidgetModal />
      </ClientContextProvider>
    </HelmetProvider>
  );
};

export default App;
