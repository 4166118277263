/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppointmentStatus {
  ACTIVE = "ACTIVE",
  CANCELLED_BY_ADMIN = "CANCELLED_BY_ADMIN",
  CANCELLED_BY_BUSINESS = "CANCELLED_BY_BUSINESS",
  CANCELLED_BY_CLIENT = "CANCELLED_BY_CLIENT",
  CONFIRMED_BY_ADMIN = "CONFIRMED_BY_ADMIN",
  CONFIRMED_BY_BUSINESS = "CONFIRMED_BY_BUSINESS",
  CONFIRMED_BY_CLIENT = "CONFIRMED_BY_CLIENT",
  CONFIRMED_MOVED = "CONFIRMED_MOVED",
  DENIED = "DENIED",
  DONE = "DONE",
  TENTATIVE = "TENTATIVE",
  TIMED_OUT = "TIMED_OUT",
}

export enum DiagnosisKind {
  Base = "Base",
  Complication = "Complication",
  Related = "Related",
}

export enum DiagnosisType {
  AcuteDisease = "AcuteDisease",
  ChronicalEarly = "ChronicalEarly",
  ChronicalFirst = "ChronicalFirst",
}

export enum Gender {
  female = "female",
  male = "male",
  not_specified = "not_specified",
}

export enum MedicationForm {
  Capsule = "Capsule",
  Powder = "Powder",
  Tablets = "Tablets",
}

export enum ObservationType {
  LaboratoryTest = "LaboratoryTest",
  Observation = "Observation",
}

export enum ProcedureExecStatus {
  Completed = "Completed",
  InProgress = "InProgress",
  Rejected = "Rejected",
  Scheduled = "Scheduled",
}

export enum ProcedureType {
  Analisys = "Analisys",
  Precedure = "Precedure",
  Recomendation = "Recomendation",
}

export enum Sex {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NOT_SPECIFIED = "NOT_SPECIFIED",
}

export enum TaxonomyType {
  CATEGORY = "CATEGORY",
  SERVICE = "SERVICE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
