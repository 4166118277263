import axios from 'axios';

import { createRequest } from './createRequest';
import { urlManager } from 'utils';
import { IContractInfo } from 'data-layer/types';
import { InfoTaxonomy, Resource } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';

interface IPopularTaxonomiesObject {
  network: { id: string };
  contract?: IContractInfo;
}

export interface IPopularTaxonomiesParams {
  networkId: string;
  GBOOKING_COREV2_RPC_URL: string;
}

export interface IPopularTaxonomiesRequest {
  networkId: string;
  contract?: IContractInfo;
  GBOOKING_COREV2_RPC_URL: string;
}

export interface IPopularTaxonomiesResult {
  resources: Resource[];
  taxonomies: InfoTaxonomy[];
}

export const createPopularTaxonomiesObject = ({
  networkId,
}: {
  networkId: string;
}): IPopularTaxonomiesObject => {
  const params: IPopularTaxonomiesObject = {
    network: {
      id: networkId,
    },
  };

  return params;
};
export async function getPopularTaxonomies({
  networkId,
  GBOOKING_COREV2_RPC_URL,
}: IPopularTaxonomiesParams): Promise<IPopularTaxonomiesResult> {
  const params = { network: { id: networkId } } as IPopularTaxonomiesObject;
  const contractID = urlManager.getContractId();
  const contractExtraId = urlManager.getContractExtraId();
  if (contractID || contractExtraId) {
    params.contract = {} as IContractInfo;
    if (contractID) {
      params.contract.id = contractID;
    }
    if (contractExtraId) {
      params.contract.extraId = contractExtraId;
    }
  }
  return axios
    .post(GBOOKING_COREV2_RPC_URL, createRequest('business.get_popular_by_network', params))
    .then((response) => {
      /*
        eslint-disable
          @typescript-eslint/no-unsafe-assignment,
          @typescript-eslint/no-unsafe-member-access,
          @typescript-eslint/no-unsafe-call,
          @typescript-eslint/no-unsafe-return
      */
      if (response) {
        if (response.data && response.data.result) {
          return response.data.result;
        }
        /*
          eslint-enable
            @typescript-eslint/no-unsafe-assignment,
            @typescript-eslint/no-unsafe-member-access,
            @typescript-eslint/no-unsafe-call,
            @typescript-eslint/no-unsafe-return
        */
      }

      return Promise.reject(new Error('common.errors.RPC_ERROR_BODY'));
    }) as Promise<IPopularTaxonomiesResult>;
}
