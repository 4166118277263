import styled from '@emotion/styled';
import { ITheme } from 'data-layer/types';

type DownloadSpinnerProps = {
  theme: ITheme;
}

export const DownloadSpinner = styled('span')(({ theme }: DownloadSpinnerProps) => ({
  width: '24px',
  height: '8px',
  background: theme.mainColor,
  marginTop: '10px',
  display: 'inline-block',
  position: 'relative',
  '@keyframes bump': {
    '0%': {
      transform: 'translate(-50%, 5px)',
    },
    '100%': {
      transform: 'translate(-50%, -5px)',
    },
  },
  '&::after': {
    content: '""',
    left: '50%',
    bottom: '0',
    transform: 'translate(-50%, 0)',
    position: 'absolute',
    border: '8px solid transparent',
    borderTopColor: theme.mainColor,
    boxSizing: 'border-box',
    animation: 'bump 0.4s ease-in-out infinite alternate',
  },
  '&::before': {
    content: '""',
    left: '50%',
    bottom: '12px',
    transform: 'translate(-50%, 0)',
    position: 'absolute',
    width: '8px',
    height: '12px',
    background: theme.mainColor,
    boxSizing: 'border-box',
    animation: 'bump 0.4s ease-in-out infinite alternate',
  },
}));
