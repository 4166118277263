import styled from '@emotion/styled';
import { ITheme } from 'data-layer/types';

type CircleSpinnerProps = {
  theme: ITheme;
}

export const CircleSpinner = styled('span')(({theme}: CircleSpinnerProps)=>({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  position: 'relative',
  animation: 'rotate 1s linear infinite',
  '&::before': {
    content: '""',
    boxSizing: 'border-box',
    position: 'absolute',
    inset: '0',
    borderRadius: '50%',
    border: `3px solid ${theme.backgroundColor}`,
    animation: 'prixClipFix 2s linear infinite',
  },
  '@keyframes rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes prixClipFix': {
    '0%': { clipPath: 'polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)' },
    '25%': { clipPath: 'polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)' },
    '50%': {
      clipPath: 'polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)',
    },
    '75%': { clipPath: 'polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)' },
    '100%': { clipPath: 'polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)' },
  },
}));
